@import 'bootstrap/scss/bootstrap';
@import 'assets/styles/helpers';
@import 'assets/styles/ds-overrides';
@import 'assets/styles/material-overrides';
@import 'assets/styles/toastr-overrides';
@import 'assets/styles/custom-form-elements';
@import 'assets/styles/cards';
@import 'assets/styles/pdf-viewer';

/* You can add global styles to this file, and also import other style files* */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.clickable {
  cursor: pointer;
}

.not-clickable {
  cursor: default;
}

html {
  scroll-behavior: smooth;
}

body {
  min-width: 320px;
  -webkit-text-size-adjust: 100%;
  margin: 0px;
  background-color: #f4f3f3;
  font-size: 0.875rem;
  line-height: 1.25;
  font-family: 'IBM Plex Sans', sans-serif;
}

a {
  outline: none;
  color: #000;
  text-decoration: none;
}

p {
  margin: 0.5em;
}

table {
  border-collapse: inherit;
}

input[type='radio' i] {
  margin: 3px 3px 0px 5px;
}

main {
  display: block;
}

.icon {
  flex: 0 0 auto;
  display: inline-flex;
  width: 1em;
  height: 1em;
  font-size: inherit;
  pointer-events: none;
  color: inherit;
  fill: currentColor;
}

// Loading page style
.mat-mdc-progress-spinner circle {
  stroke: #ffcd00;
}

// --------------------------
// TODO: À RETRAVAILLER
// --------------------------
.mat-mdc-table * {
  box-sizing: initial;
}

.tab-container {
  overflow: auto;
  box-shadow: 0 5px 5px -3px rgba(139, 90, 90, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  margin: 0px auto;
  text-align: left;

  .mat-mdc-table {
    background-color: white;
    width: 100%;

    th {
      padding: 15px;
      font-weight: normal;
      border-bottom: 1px solid grey;

      .table-th {
        display: inline-flex;
        align-items: center;

        .filter {
          margin: auto;
        }
      }
    }

    tr {
      border-bottom: 1px solid lightgrey;
    }

    td {
      padding: 15px;
    }

    .mat-mdc-row {
      cursor: pointer;

      &:hover {
        background-color: #c7c7c7;
      }
    }
  }
}

// Keyframes
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.title-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.alertes-publies {
  margin: 2rem 3rem 0 3rem;
}

.alertes.no-space .alertes-publies {
  margin: 0;
}

.alertes.accueil-nc .alertes-publies {
  margin: 2rem 2rem 0 2rem;
}
