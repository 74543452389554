@import "./variables";

.display-phone {
  display: none;
}

@media screen and (max-width: $breakpoint-phone) {
  .display-desktop {
    display: none;
  }

  .display-phone {
    display: block;
  }
}
