// --------------------------
// ÉLÉMENTS DE FORMULAIRE
// --------------------------
.ds-input-group {
  position: relative;
  margin-bottom: 1rem; // add space between form inputs

  .ds-input-group__input {
    .iti.ds-input-group {
      margin-bottom: 0; // except for a nested input-group (added by intl-tel-input for input type=tel)
    }
  }
}

.ds-input-group--error .ds-input-group__input::before {
  display: none;
}

.ds-input-group__select option {
  background-color: #fefefe;
}

// TODO: should understand why DS lib have a margin-top on select field
.ds-input-group__select {
  &::after {
    margin-top: 0.9rem; // center select arrow vertically
  }

  select {
    height: 2rem;
    margin-bottom: 0;
    margin-top: 0; // align select field with the rest of DS fields when inline (text, date...)
  }
}

.ds-input-group__textarea {
  resize: vertical;
}

.ds-input-group__input input {
  font-size: 0.875rem;
}

.ds-input-group__input:disabled,
.ds-input-group__textarea:disabled,
.ds-input-group__select select:disabled {
  border-color: #909090;
  background-color: #e9e9e9;
  cursor: not-allowed;
  color: #bcbcbc;
  opacity: 1;
}

// Indicateur de longueur des inputs text et textareas (ajout)
.form-input__length-hint {
  position: relative;
  color: #a6a6a6;
  font-size: 0.875rem;

  span {
    position: absolute;
    right: 0;
  }

  &--full {
    color: #be0e25;
  }
}

// Titre de radio-group (ajout)
.radio-group-title {
  margin: 0 0 16px;
}

.ds-radio-group {
  margin-bottom: 24px;
}

.ds-radio-group:not(.ds-radio-group--alternative) .ds-radio-group__radio-label {
  margin-bottom: 8px;
}

// --------------------------
// Erreurs sur les éléments de formulaire
// --------------------------
// Flèche indicatrice
.ds-input-group--error .ds-input-group__label::before {
  content: none;
}

// --------------------------
// BOUTONS
// --------------------------
.ds-btn {
  display: inline-flex;
  align-items: center;
  transition: all ease-in-out 0.1s;
  line-height: 1;
  text-transform: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;

  .icon {
    font-size: 1.5em;
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    max-width: none;
  }
}

.ds-btn--primary:disabled {
  background: #e9e9e9;
}

.ds-btn.is-disabled {
  border: none;
  background-color: #a6a6a6;
  color: #565656;
  cursor: not-allowed;
}
