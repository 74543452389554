.ipv_flag {
  width: 20px;
}
.ipv_flag.ipv_be {
  width: 18px;
}
.ipv_flag.ipv_ch {
  width: 15px;
}
.ipv_flag.ipv_mc {
  width: 19px;
}
.ipv_flag.ipv_ne {
  width: 18px;
}
.ipv_flag.ipv_np {
  width: 13px;
}
.ipv_flag.ipv_va {
  width: 15px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .ipv_flag {
    background-size: 5762px 15px;
  }
}
.ipv_flag.ipv_ac {
  height: 10px;
  background-position: 0px 0px;
}
.ipv_flag.ipv_ad {
  height: 14px;
  background-position: -22px 0px;
}
.ipv_flag.ipv_ae {
  height: 10px;
  background-position: -44px 0px;
}
.ipv_flag.ipv_af {
  height: 14px;
  background-position: -66px 0px;
}
.ipv_flag.ipv_ag {
  height: 14px;
  background-position: -88px 0px;
}
.ipv_flag.ipv_ai {
  height: 10px;
  background-position: -110px 0px;
}
.ipv_flag.ipv_al {
  height: 15px;
  background-position: -132px 0px;
}
.ipv_flag.ipv_am {
  height: 10px;
  background-position: -154px 0px;
}
.ipv_flag.ipv_ao {
  height: 14px;
  background-position: -176px 0px;
}
.ipv_flag.ipv_aq {
  height: 14px;
  background-position: -198px 0px;
}
.ipv_flag.ipv_ar {
  height: 13px;
  background-position: -220px 0px;
}
.ipv_flag.ipv_as {
  height: 10px;
  background-position: -242px 0px;
}
.ipv_flag.ipv_at {
  height: 14px;
  background-position: -264px 0px;
}
.ipv_flag.ipv_au {
  height: 10px;
  background-position: -286px 0px;
}
.ipv_flag.ipv_aw {
  height: 14px;
  background-position: -308px 0px;
}
.ipv_flag.ipv_ax {
  height: 13px;
  background-position: -330px 0px;
}
.ipv_flag.ipv_az {
  height: 10px;
  background-position: -352px 0px;
}
.ipv_flag.ipv_ba {
  height: 10px;
  background-position: -374px 0px;
}
.ipv_flag.ipv_bb {
  height: 14px;
  background-position: -396px 0px;
}
.ipv_flag.ipv_bd {
  height: 12px;
  background-position: -418px 0px;
}
.ipv_flag.ipv_be {
  height: 15px;
  background-position: -440px 0px;
}
.ipv_flag.ipv_bf {
  height: 14px;
  background-position: -460px 0px;
}
.ipv_flag.ipv_bg {
  height: 12px;
  background-position: -482px 0px;
}
.ipv_flag.ipv_bh {
  height: 12px;
  background-position: -504px 0px;
}
.ipv_flag.ipv_bi {
  height: 12px;
  background-position: -526px 0px;
}
.ipv_flag.ipv_bj {
  height: 14px;
  background-position: -548px 0px;
}
.ipv_flag.ipv_bl {
  height: 14px;
  background-position: -570px 0px;
}
.ipv_flag.ipv_bm {
  height: 10px;
  background-position: -592px 0px;
}
.ipv_flag.ipv_bn {
  height: 10px;
  background-position: -614px 0px;
}
.ipv_flag.ipv_bo {
  height: 14px;
  background-position: -636px 0px;
}
.ipv_flag.ipv_bq {
  height: 14px;
  background-position: -658px 0px;
}
.ipv_flag.ipv_br {
  height: 14px;
  background-position: -680px 0px;
}
.ipv_flag.ipv_bs {
  height: 10px;
  background-position: -702px 0px;
}
.ipv_flag.ipv_bt {
  height: 14px;
  background-position: -724px 0px;
}
.ipv_flag.ipv_bv {
  height: 15px;
  background-position: -746px 0px;
}
.ipv_flag.ipv_bw {
  height: 14px;
  background-position: -768px 0px;
}
.ipv_flag.ipv_by {
  height: 10px;
  background-position: -790px 0px;
}
.ipv_flag.ipv_bz {
  height: 12px;
  background-position: -812px 0px;
}
.ipv_flag.ipv_ca {
  height: 10px;
  background-position: -834px 0px;
}
.ipv_flag.ipv_cc {
  height: 10px;
  background-position: -856px 0px;
}
.ipv_flag.ipv_cd {
  height: 15px;
  background-position: -878px 0px;
}
.ipv_flag.ipv_cf {
  height: 14px;
  background-position: -900px 0px;
}
.ipv_flag.ipv_cg {
  height: 14px;
  background-position: -922px 0px;
}
.ipv_flag.ipv_ch {
  height: 15px;
  background-position: -944px 0px;
}
.ipv_flag.ipv_ci {
  height: 14px;
  background-position: -961px 0px;
}
.ipv_flag.ipv_ck {
  height: 10px;
  background-position: -983px 0px;
}
.ipv_flag.ipv_cl {
  height: 14px;
  background-position: -1005px 0px;
}
.ipv_flag.ipv_cm {
  height: 14px;
  background-position: -1027px 0px;
}
.ipv_flag.ipv_cn {
  height: 14px;
  background-position: -1049px 0px;
}
.ipv_flag.ipv_co {
  height: 14px;
  background-position: -1071px 0px;
}
.ipv_flag.ipv_cp {
  height: 14px;
  background-position: -1093px 0px;
}
.ipv_flag.ipv_cq {
  height: 12px;
  background-position: -1115px 0px;
}
.ipv_flag.ipv_cr {
  height: 12px;
  background-position: -1137px 0px;
}
.ipv_flag.ipv_cu {
  height: 10px;
  background-position: -1159px 0px;
}
.ipv_flag.ipv_cv {
  height: 12px;
  background-position: -1181px 0px;
}
.ipv_flag.ipv_cw {
  height: 14px;
  background-position: -1203px 0px;
}
.ipv_flag.ipv_cx {
  height: 10px;
  background-position: -1225px 0px;
}
.ipv_flag.ipv_cy {
  height: 14px;
  background-position: -1247px 0px;
}
.ipv_flag.ipv_cz {
  height: 14px;
  background-position: -1269px 0px;
}
.ipv_flag.ipv_de {
  height: 12px;
  background-position: -1291px 0px;
}
.ipv_flag.ipv_dg {
  height: 10px;
  background-position: -1313px 0px;
}
.ipv_flag.ipv_dj {
  height: 14px;
  background-position: -1335px 0px;
}
.ipv_flag.ipv_dk {
  height: 15px;
  background-position: -1357px 0px;
}
.ipv_flag.ipv_dm {
  height: 10px;
  background-position: -1379px 0px;
}
.ipv_flag.ipv_do {
  height: 14px;
  background-position: -1401px 0px;
}
.ipv_flag.ipv_dz {
  height: 14px;
  background-position: -1423px 0px;
}
.ipv_flag.ipv_ea {
  height: 14px;
  background-position: -1445px 0px;
}
.ipv_flag.ipv_ec {
  height: 14px;
  background-position: -1467px 0px;
}
.ipv_flag.ipv_ee {
  height: 13px;
  background-position: -1489px 0px;
}
.ipv_flag.ipv_eg {
  height: 14px;
  background-position: -1511px 0px;
}
.ipv_flag.ipv_eh {
  height: 10px;
  background-position: -1533px 0px;
}
.ipv_flag.ipv_er {
  height: 10px;
  background-position: -1555px 0px;
}
.ipv_flag.ipv_es {
  height: 14px;
  background-position: -1577px 0px;
}
.ipv_flag.ipv_et {
  height: 10px;
  background-position: -1599px 0px;
}
.ipv_flag.ipv_eu {
  height: 14px;
  background-position: -1621px 0px;
}
.ipv_flag.ipv_ez {
  height: 14px;
  background-position: -1643px 0px;
}
.ipv_flag.ipv_fi {
  height: 12px;
  background-position: -1665px 0px;
}
.ipv_flag.ipv_fj {
  height: 10px;
  background-position: -1687px 0px;
}
.ipv_flag.ipv_fk {
  height: 10px;
  background-position: -1709px 0px;
}
.ipv_flag.ipv_fm {
  height: 11px;
  background-position: -1731px 0px;
}
.ipv_flag.ipv_fo {
  height: 15px;
  background-position: -1753px 0px;
}
.ipv_flag.ipv_fr {
  height: 14px;
  background-position: -1775px 0px;
}
.ipv_flag.ipv_fx {
  height: 14px;
  background-position: -1797px 0px;
}
.ipv_flag.ipv_ga {
  height: 15px;
  background-position: -1819px 0px;
}
.ipv_flag.ipv_gb {
  height: 10px;
  background-position: -1841px 0px;
}
.ipv_flag.ipv_gd {
  height: 12px;
  background-position: -1863px 0px;
}
.ipv_flag.ipv_ge {
  height: 14px;
  background-position: -1885px 0px;
}
.ipv_flag.ipv_gf {
  height: 14px;
  background-position: -1907px 0px;
}
.ipv_flag.ipv_gg {
  height: 14px;
  background-position: -1929px 0px;
}
.ipv_flag.ipv_gh {
  height: 14px;
  background-position: -1951px 0px;
}
.ipv_flag.ipv_gi {
  height: 10px;
  background-position: -1973px 0px;
}
.ipv_flag.ipv_gl {
  height: 14px;
  background-position: -1995px 0px;
}
.ipv_flag.ipv_gm {
  height: 14px;
  background-position: -2017px 0px;
}
.ipv_flag.ipv_gn {
  height: 14px;
  background-position: -2039px 0px;
}
.ipv_flag.ipv_gp {
  height: 14px;
  background-position: -2061px 0px;
}
.ipv_flag.ipv_gq {
  height: 14px;
  background-position: -2083px 0px;
}
.ipv_flag.ipv_gr {
  height: 14px;
  background-position: -2105px 0px;
}
.ipv_flag.ipv_gs {
  height: 10px;
  background-position: -2127px 0px;
}
.ipv_flag.ipv_gt {
  height: 13px;
  background-position: -2149px 0px;
}
.ipv_flag.ipv_gu {
  height: 11px;
  background-position: -2171px 0px;
}
.ipv_flag.ipv_gw {
  height: 10px;
  background-position: -2193px 0px;
}
.ipv_flag.ipv_gy {
  height: 12px;
  background-position: -2215px 0px;
}
.ipv_flag.ipv_hk {
  height: 14px;
  background-position: -2237px 0px;
}
.ipv_flag.ipv_hm {
  height: 10px;
  background-position: -2259px 0px;
}
.ipv_flag.ipv_hn {
  height: 10px;
  background-position: -2281px 0px;
}
.ipv_flag.ipv_hr {
  height: 10px;
  background-position: -2303px 0px;
}
.ipv_flag.ipv_ht {
  height: 12px;
  background-position: -2325px 0px;
}
.ipv_flag.ipv_hu {
  height: 10px;
  background-position: -2347px 0px;
}
.ipv_flag.ipv_ic {
  height: 14px;
  background-position: -2369px 0px;
}
.ipv_flag.ipv_id {
  height: 14px;
  background-position: -2391px 0px;
}
.ipv_flag.ipv_ie {
  height: 10px;
  background-position: -2413px 0px;
}
.ipv_flag.ipv_il {
  height: 15px;
  background-position: -2435px 0px;
}
.ipv_flag.ipv_im {
  height: 10px;
  background-position: -2457px 0px;
}
.ipv_flag.ipv_in {
  height: 14px;
  background-position: -2479px 0px;
}
.ipv_flag.ipv_io {
  height: 10px;
  background-position: -2501px 0px;
}
.ipv_flag.ipv_iq {
  height: 14px;
  background-position: -2523px 0px;
}
.ipv_flag.ipv_ir {
  height: 12px;
  background-position: -2545px 0px;
}
.ipv_flag.ipv_is {
  height: 15px;
  background-position: -2567px 0px;
}
.ipv_flag.ipv_it {
  height: 14px;
  background-position: -2589px 0px;
}
.ipv_flag.ipv_je {
  height: 12px;
  background-position: -2611px 0px;
}
.ipv_flag.ipv_jm {
  height: 10px;
  background-position: -2633px 0px;
}
.ipv_flag.ipv_jo {
  height: 10px;
  background-position: -2655px 0px;
}
.ipv_flag.ipv_jp {
  height: 14px;
  background-position: -2677px 0px;
}
.ipv_flag.ipv_ke {
  height: 14px;
  background-position: -2699px 0px;
}
.ipv_flag.ipv_kg {
  height: 12px;
  background-position: -2721px 0px;
}
.ipv_flag.ipv_kh {
  height: 13px;
  background-position: -2743px 0px;
}
.ipv_flag.ipv_ki {
  height: 10px;
  background-position: -2765px 0px;
}
.ipv_flag.ipv_km {
  height: 12px;
  background-position: -2787px 0px;
}
.ipv_flag.ipv_kn {
  height: 14px;
  background-position: -2809px 0px;
}
.ipv_flag.ipv_kp {
  height: 10px;
  background-position: -2831px 0px;
}
.ipv_flag.ipv_kr {
  height: 14px;
  background-position: -2853px 0px;
}
.ipv_flag.ipv_kw {
  height: 10px;
  background-position: -2875px 0px;
}
.ipv_flag.ipv_ky {
  height: 10px;
  background-position: -2897px 0px;
}
.ipv_flag.ipv_kz {
  height: 10px;
  background-position: -2919px 0px;
}
.ipv_flag.ipv_la {
  height: 14px;
  background-position: -2941px 0px;
}
.ipv_flag.ipv_lb {
  height: 14px;
  background-position: -2963px 0px;
}
.ipv_flag.ipv_lc {
  height: 10px;
  background-position: -2985px 0px;
}
.ipv_flag.ipv_li {
  height: 12px;
  background-position: -3007px 0px;
}
.ipv_flag.ipv_lk {
  height: 10px;
  background-position: -3029px 0px;
}
.ipv_flag.ipv_lr {
  height: 11px;
  background-position: -3051px 0px;
}
.ipv_flag.ipv_ls {
  height: 14px;
  background-position: -3073px 0px;
}
.ipv_flag.ipv_lt {
  height: 12px;
  background-position: -3095px 0px;
}
.ipv_flag.ipv_lu {
  height: 12px;
  background-position: -3117px 0px;
}
.ipv_flag.ipv_lv {
  height: 10px;
  background-position: -3139px 0px;
}
.ipv_flag.ipv_ly {
  height: 10px;
  background-position: -3161px 0px;
}
.ipv_flag.ipv_ma {
  height: 14px;
  background-position: -3183px 0px;
}
.ipv_flag.ipv_mc {
  height: 15px;
  background-position: -3205px 0px;
}
.ipv_flag.ipv_md {
  height: 10px;
  background-position: -3226px 0px;
}
.ipv_flag.ipv_me {
  height: 10px;
  background-position: -3248px 0px;
}
.ipv_flag.ipv_mf {
  height: 14px;
  background-position: -3270px 0px;
}
.ipv_flag.ipv_mg {
  height: 14px;
  background-position: -3292px 0px;
}
.ipv_flag.ipv_mh {
  height: 11px;
  background-position: -3314px 0px;
}
.ipv_flag.ipv_mk {
  height: 10px;
  background-position: -3336px 0px;
}
.ipv_flag.ipv_ml {
  height: 14px;
  background-position: -3358px 0px;
}
.ipv_flag.ipv_mm {
  height: 14px;
  background-position: -3380px 0px;
}
.ipv_flag.ipv_mn {
  height: 10px;
  background-position: -3402px 0px;
}
.ipv_flag.ipv_mo {
  height: 14px;
  background-position: -3424px 0px;
}
.ipv_flag.ipv_mp {
  height: 10px;
  background-position: -3446px 0px;
}
.ipv_flag.ipv_mq {
  height: 14px;
  background-position: -3468px 0px;
}
.ipv_flag.ipv_mr {
  height: 14px;
  background-position: -3490px 0px;
}
.ipv_flag.ipv_ms {
  height: 10px;
  background-position: -3512px 0px;
}
.ipv_flag.ipv_mt {
  height: 14px;
  background-position: -3534px 0px;
}
.ipv_flag.ipv_mu {
  height: 14px;
  background-position: -3556px 0px;
}
.ipv_flag.ipv_mv {
  height: 14px;
  background-position: -3578px 0px;
}
.ipv_flag.ipv_mw {
  height: 14px;
  background-position: -3600px 0px;
}
.ipv_flag.ipv_mx {
  height: 12px;
  background-position: -3622px 0px;
}
.ipv_flag.ipv_my {
  height: 10px;
  background-position: -3644px 0px;
}
.ipv_flag.ipv_mz {
  height: 14px;
  background-position: -3666px 0px;
}
.ipv_flag.ipv_na {
  height: 14px;
  background-position: -3688px 0px;
}
.ipv_flag.ipv_nc {
  height: 10px;
  background-position: -3710px 0px;
}
.ipv_flag.ipv_ne {
  height: 15px;
  background-position: -3732px 0px;
}
.ipv_flag.ipv_nf {
  height: 10px;
  background-position: -3752px 0px;
}
.ipv_flag.ipv_ng {
  height: 10px;
  background-position: -3774px 0px;
}
.ipv_flag.ipv_ni {
  height: 12px;
  background-position: -3796px 0px;
}
.ipv_flag.ipv_nl {
  height: 14px;
  background-position: -3818px 0px;
}
.ipv_flag.ipv_no {
  height: 15px;
  background-position: -3840px 0px;
}
.ipv_flag.ipv_np {
  height: 15px;
  background-position: -3862px 0px;
}
.ipv_flag.ipv_nr {
  height: 10px;
  background-position: -3877px 0px;
}
.ipv_flag.ipv_nu {
  height: 10px;
  background-position: -3899px 0px;
}
.ipv_flag.ipv_nz {
  height: 10px;
  background-position: -3921px 0px;
}
.ipv_flag.ipv_om {
  height: 10px;
  background-position: -3943px 0px;
}
.ipv_flag.ipv_pa {
  height: 14px;
  background-position: -3965px 0px;
}
.ipv_flag.ipv_pe {
  height: 14px;
  background-position: -3987px 0px;
}
.ipv_flag.ipv_pf {
  height: 14px;
  background-position: -4009px 0px;
}
.ipv_flag.ipv_pg {
  height: 15px;
  background-position: -4031px 0px;
}
.ipv_flag.ipv_ph {
  height: 10px;
  background-position: -4053px 0px;
}
.ipv_flag.ipv_pk {
  height: 14px;
  background-position: -4075px 0px;
}
.ipv_flag.ipv_pl {
  height: 13px;
  background-position: -4097px 0px;
}
.ipv_flag.ipv_pm {
  height: 14px;
  background-position: -4119px 0px;
}
.ipv_flag.ipv_pn {
  height: 10px;
  background-position: -4141px 0px;
}
.ipv_flag.ipv_pr {
  height: 14px;
  background-position: -4163px 0px;
}
.ipv_flag.ipv_ps {
  height: 10px;
  background-position: -4185px 0px;
}
.ipv_flag.ipv_pt {
  height: 14px;
  background-position: -4207px 0px;
}
.ipv_flag.ipv_pw {
  height: 13px;
  background-position: -4229px 0px;
}
.ipv_flag.ipv_py {
  height: 11px;
  background-position: -4251px 0px;
}
.ipv_flag.ipv_qa {
  height: 8px;
  background-position: -4273px 0px;
}
.ipv_flag.ipv_re {
  height: 14px;
  background-position: -4295px 0px;
}
.ipv_flag.ipv_ro {
  height: 14px;
  background-position: -4317px 0px;
}
.ipv_flag.ipv_rs {
  height: 14px;
  background-position: -4339px 0px;
}
.ipv_flag.ipv_ru {
  height: 14px;
  background-position: -4361px 0px;
}
.ipv_flag.ipv_rw {
  height: 14px;
  background-position: -4383px 0px;
}
.ipv_flag.ipv_sa {
  height: 14px;
  background-position: -4405px 0px;
}
.ipv_flag.ipv_sb {
  height: 10px;
  background-position: -4427px 0px;
}
.ipv_flag.ipv_sc {
  height: 10px;
  background-position: -4449px 0px;
}
.ipv_flag.ipv_sd {
  height: 10px;
  background-position: -4471px 0px;
}
.ipv_flag.ipv_se {
  height: 13px;
  background-position: -4493px 0px;
}
.ipv_flag.ipv_sg {
  height: 14px;
  background-position: -4515px 0px;
}
.ipv_flag.ipv_sh {
  height: 10px;
  background-position: -4537px 0px;
}
.ipv_flag.ipv_si {
  height: 10px;
  background-position: -4559px 0px;
}
.ipv_flag.ipv_sj {
  height: 15px;
  background-position: -4581px 0px;
}
.ipv_flag.ipv_sk {
  height: 14px;
  background-position: -4603px 0px;
}
.ipv_flag.ipv_sl {
  height: 14px;
  background-position: -4625px 0px;
}
.ipv_flag.ipv_sm {
  height: 15px;
  background-position: -4647px 0px;
}
.ipv_flag.ipv_sn {
  height: 14px;
  background-position: -4669px 0px;
}
.ipv_flag.ipv_so {
  height: 14px;
  background-position: -4691px 0px;
}
.ipv_flag.ipv_sr {
  height: 14px;
  background-position: -4713px 0px;
}
.ipv_flag.ipv_ss {
  height: 10px;
  background-position: -4735px 0px;
}
.ipv_flag.ipv_st {
  height: 10px;
  background-position: -4757px 0px;
}
.ipv_flag.ipv_su {
  height: 10px;
  background-position: -4779px 0px;
}
.ipv_flag.ipv_sv {
  height: 12px;
  background-position: -4801px 0px;
}
.ipv_flag.ipv_sx {
  height: 14px;
  background-position: -4823px 0px;
}
.ipv_flag.ipv_sy {
  height: 14px;
  background-position: -4845px 0px;
}
.ipv_flag.ipv_sz {
  height: 14px;
  background-position: -4867px 0px;
}
.ipv_flag.ipv_ta {
  height: 10px;
  background-position: -4889px 0px;
}
.ipv_flag.ipv_tc {
  height: 10px;
  background-position: -4911px 0px;
}
.ipv_flag.ipv_td {
  height: 14px;
  background-position: -4933px 0px;
}
.ipv_flag.ipv_tf {
  height: 14px;
  background-position: -4955px 0px;
}
.ipv_flag.ipv_tg {
  height: 13px;
  background-position: -4977px 0px;
}
.ipv_flag.ipv_th {
  height: 14px;
  background-position: -4999px 0px;
}
.ipv_flag.ipv_tj {
  height: 10px;
  background-position: -5021px 0px;
}
.ipv_flag.ipv_tk {
  height: 10px;
  background-position: -5043px 0px;
}
.ipv_flag.ipv_tl {
  height: 10px;
  background-position: -5065px 0px;
}
.ipv_flag.ipv_tm {
  height: 14px;
  background-position: -5087px 0px;
}
.ipv_flag.ipv_tn {
  height: 14px;
  background-position: -5109px 0px;
}
.ipv_flag.ipv_to {
  height: 10px;
  background-position: -5131px 0px;
}
.ipv_flag.ipv_tr {
  height: 14px;
  background-position: -5153px 0px;
}
.ipv_flag.ipv_tt {
  height: 12px;
  background-position: -5175px 0px;
}
.ipv_flag.ipv_tv {
  height: 10px;
  background-position: -5197px 0px;
}
.ipv_flag.ipv_tw {
  height: 14px;
  background-position: -5219px 0px;
}
.ipv_flag.ipv_tz {
  height: 14px;
  background-position: -5241px 0px;
}
.ipv_flag.ipv_ua {
  height: 14px;
  background-position: -5263px 0px;
}
.ipv_flag.ipv_ug {
  height: 14px;
  background-position: -5285px 0px;
}
.ipv_flag.ipv_uk {
  height: 10px;
  background-position: -5307px 0px;
}
.ipv_flag.ipv_um {
  height: 11px;
  background-position: -5329px 0px;
}
.ipv_flag.ipv_un {
  height: 14px;
  background-position: -5351px 0px;
}
.ipv_flag.ipv_us {
  height: 11px;
  background-position: -5373px 0px;
}
.ipv_flag.ipv_uy {
  height: 14px;
  background-position: -5395px 0px;
}
.ipv_flag.ipv_uz {
  height: 10px;
  background-position: -5417px 0px;
}
.ipv_flag.ipv_va {
  height: 15px;
  background-position: -5439px 0px;
}
.ipv_flag.ipv_vc {
  height: 14px;
  background-position: -5456px 0px;
}
.ipv_flag.ipv_ve {
  height: 14px;
  background-position: -5478px 0px;
}
.ipv_flag.ipv_vg {
  height: 10px;
  background-position: -5500px 0px;
}
.ipv_flag.ipv_vi {
  height: 14px;
  background-position: -5522px 0px;
}
.ipv_flag.ipv_vn {
  height: 14px;
  background-position: -5544px 0px;
}
.ipv_flag.ipv_vu {
  height: 12px;
  background-position: -5566px 0px;
}
.ipv_flag.ipv_wf {
  height: 14px;
  background-position: -5588px 0px;
}
.ipv_flag.ipv_ws {
  height: 10px;
  background-position: -5610px 0px;
}
.ipv_flag.ipv_xk {
  height: 15px;
  background-position: -5632px 0px;
}
.ipv_flag.ipv_ye {
  height: 14px;
  background-position: -5654px 0px;
}
.ipv_flag.ipv_yt {
  height: 14px;
  background-position: -5676px 0px;
}
.ipv_flag.ipv_za {
  height: 14px;
  background-position: -5698px 0px;
}
.ipv_flag.ipv_zm {
  height: 14px;
  background-position: -5720px 0px;
}
.ipv_flag.ipv_zw {
  height: 10px;
  background-position: -5742px 0px;
}

.ipv_flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url('../assets/png/flags.png');
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 20px 0;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .ipv_flag {
    background-image: url('../assets/png/flags@2x.png');
  }
}

.ipv_flag.ipv_np {
  background-color: transparent;
}
