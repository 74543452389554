@import './variables';

// --------------------------
// TABLEAUX
// --------------------------
// entête de tableau
tr.mat-mdc-header-row {
  height: 47px !important;
}

// entête de colonne
.mat-mdc-header-cell {
  &,
  & .mat-sort-header-stem,
  & .mat-sort-header-indicator {
    color: #a6a6a6;
  }
}

// entête de colonne trié
.mat-sort-header-sorted {
  &,
  & .mat-sort-header-stem,
  & .mat-sort-header-indicator {
    color: #071a39;
  }
}

.mat-sort-header-arrow,
[dir='rtl'] .mat-sort-header-position-before .mat-sort-header-arrow {
  margin: 0 0 0 16px !important;
}

.mat-sort-header-content {
  text-align: initial !important;
}

// Ligne
tr.mat-mdc-row,
tr.mat-mdc-footer-row {
  height: 3.5rem !important;
}

.mat-mdc-row {
  &:hover {
    background: #f5f7fb;
  }
}

// Cellule
.mat-mdc-header-cell,
.mat-mdc-cell,
.mat-mdc-footer-cell {
  padding: 16px 8px !important;

  &:first-child {
    padding-left: 24px !important;
  }

  &:last-child {
    padding-right: 24px !important;
  }

  strong {
    font-weight: 600;
  }
}

.mat-mdc-cell,
.mat-mdc-footer-cell {
  color: #071a39;
}

// bordures
mat-row,
mat-header-row,
mat-footer-row,
th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  border-bottom: 1px solid #f5f7fb;
}

// --------------------------
// Popins
// --------------------------
.cdk-overlay-container .cdk-global-overlay-wrapper {
  width: 100vw;
}

.cdk-overlay-dark-backdrop {
  background: #071a39;
  opacity: 0.6 !important;
}

.cdk-overlay-pane {
  max-width: 90vw !important;

  @media screen and (max-width: $breakpoint-phone) {
    max-width: calc(100vw - 32px) !important;
  }
}

.mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 4px !important;
  box-shadow: none;

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

// Tooltip
.mat-mdc-tooltip {
  font-size: 0.8rem;
}

.mde-popover-direction-arrow {
  border-color: transparent transparent rgba(255, 255, 255, 1) !important;
}

// Checkbox
.mat-checkbox-layout {
  white-space: normal !important;
}

// Datepicker
.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #be0e25;
}

.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #be0e25;
}

.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #ffcd00 !important;
}

// matdatepicker + input date DS (bpi)
.ds-input-group.ds-input-group--date {
  .ds-input-group__input {
    .mat-datepicker-toggle {
      position: absolute;
      right: 0.8rem;

      > .mdc-icon-button {
        width: 2rem;
        height: 2rem;
        padding: 0.3rem;
      }
    }
  }
}
